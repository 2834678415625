<template>
  <div class="pc-page-main edituser">
    <el-form
      :model="formData"
      :rules="rules"
      ref="userForm"
      style="width: 455px"
      label-width="80px"
      class="no-pos"
    >
      <el-form-item label="用户头像" prop="headImg">
        <img
          v-if="formData.headImg"
          :src="formData.headImg"
          class="headImg"
          alt=""
        />
        <img v-else src="./../../../assets/images/header/user-logo.png" />
        <HeadImgContent
          class="upload-demo"
          :headers="token"
          :action="uploadImageUrl"
          :fileType="fileType"
          multiple
          :limit="1"
          :fileUrlsArray="this.fileUrlsArray"
          @getFileUrls="getFileUrls"
        >
        </HeadImgContent>
      </el-form-item>
      <el-form-item label="用户名" prop="userName">
        <el-input
          required
          size="small"
          maxlength="20"
          clearable
          style="height: 34px"
          v-model="formData.userName"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="密码"
        v-if="type === 'ADD'"
        prop="loginPwd"
        :rules="[
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: validPwd, trigger: ['blur', 'input'] },
        ]"
      >
        <el-input
          size="small"
          maxlength="20"
          show-password
          clearable
          type="password"
          v-model="formData.loginPwd"
          placeholder="只可输入英文+数字，需大于6位小于10位"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="修改密码"
        v-if="type === 'UPDATE'"
        prop="loginPwd"
        :rules="[{ validator: validPwd, trigger: ['blur', 'input'] }]"
      >
        <el-input
          size="small"
          maxlength="20"
          show-password
          clearable
          type="password"
          v-model="formData.loginPwd"
          placeholder="只可输入英文+数字，需大于6位小于10位"
        ></el-input>
      </el-form-item>
      <el-form-item label="部门" prop="departmentId">
        <el-select
          size="small"
          v-model="formData.departmentId"
          filterable
          placeholder="部门"
          @change="changeDepartment(formData.departmentId)"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="岗位" prop="positionId">
        <el-select
          size="small"
          v-model="formData.positionId"
          filterable
          placeholder="岗位"
          required
        >
          <el-option
            v-for="item in positionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select
          size="small"
          v-model="formData.sex"
          filterable
          placeholder="性别"
        >
          <el-option
            v-for="item in sexList"
            :key="item.value"
            :label="item.value"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机" prop="mobileNo">
        <el-input
          size="small"
          maxlength="11"
          clearable
          v-model="formData.mobileNo"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input
          size="small"
          maxlength="30"
          clearable
          v-model="formData.email"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import HeadImgContent from './headImgContent.vue';
const checkPwd = (rule, value, callback) => {
  if (value === '') {
    callback();
  } else {
    let reg = /^[A-Za-z0-9]{6,10}$/; // eslint-disable-line
    const val = reg.test(value);
    if (val !== true && value !== null) {
      callback(new Error('只可输入英文+数字，需大于6位小于10位'));
    } else {
      callback();
    }
    callback();
  }
};
const checkMobileNoWithNull = (rule, value, callback) => {
  if (value !== '' && value !== undefined && value !== null) {
    const reg = /^1\d{10}$/;
    const val = reg.test(value);
    if (val !== true) {
      callback(new Error('请输入正确的手机号格式'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};
const checkEmail = (rule, value, callback) => {
  if (value === '' || value === undefined) {
    callback();
  } else {
    const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    const val = reg.test(value);
    if (val !== true && value !== null) {
      callback(new Error('邮箱格式不正确'));
    } else {
      callback();
    }
    callback();
  }
};
export default {
  name: 'frameList',
  components: {
    HeadImgContent,
  },
  props: {
    rowData: {
      type: Object,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      validPwd: checkPwd,
      fileUrlsArray: [],
      token: { Authorization: 'Bearer ' + this.$store.getters.getAccessToken },
      uploadImageUrl: process.env.VUE_APP_UPLOAD_FILE,
      departmentList: [],
      positionList: [],
      formData: Object.assign({}, this.rowData),
      sexList: [
        {
          code: 1,
          value: '男',
        },
        {
          code: 2,
          value: '女',
        },
      ],
      groupIdList: [],
      groupTreeData: [],
      groupProps: {
        children: 'subMenu',
        value: 'id',
        label: 'menuName',
      },
      rules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],

        mobileNo: [
          {
            required: false,
            validator: checkMobileNoWithNull,
            trigger: 'blur',
          },
        ],
        email: [{ required: false, validator: checkEmail, trigger: 'blur' }],
        departmentId: [
          { required: true, message: '请选择部门', trigger: 'blur' },
        ],
        positionId: [
          { required: true, message: '请选择岗位', trigger: 'blur' },
        ],
      },
      fileType: '.jpg,.jpeg,.gif,.png,.bmp',
    };
  },
  created() {
    this.initDepartmentList();
    if (this.type === 'UPDATE') {
      this.getPosition(this.formData.departmentId);
    }
  },
  computed: {
    formPropName() {
      return function (prop) {
        return this.type === 'UPDATE' ? '' : prop;
      };
    },
  },
  methods: {
    initDepartmentList() {
      this.ajax({
        url: '/uac/department/getDepartmentList',
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.departmentList = data;
        },
      });
    },
    getPosition(departmentId) {
      this.positionList = [];
      this.ajax({
        url: '/uac/position/getPositionListByDepartmentId/' + departmentId,
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.positionList = data;
        },
      });
    },
    changeDepartment(departmentId) {
      this.positionList = [];
      this.ajax({
        url: '/uac/position/getPositionListByDepartmentId/' + departmentId,
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.positionList = data;
          this.formData.positionId = '';
        },
      });
    },
    submitForm(fn) {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.saveUserFn(fn);
        } else {
          return false;
        }
      });
    },
    saveUserFn(fn) {
      console.info('this.formData', this.formData);
      this.ajax({
        url: '/uac/crmUser/save',
        data: this.formData || '',
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            fn && fn(res);
            this.successTip('操作成功');
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    getFileUrls(titleImg, fileNameArray, fileUrlArray) {
      this.formData.headImg = fileUrlArray[0];
      console.info('this.formData', this.formData.headImg);
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scope>
//加上这个整体布局就会错乱，先屏蔽掉
// .el-input--small .el-input__inner{
//   height: 34px!important;
// }
.menu-cascader {
  width: 100%;
  .el-cascader__label {
    line-height: 36px;
  }
}
.pc-page-main {
  margin-left: -10px;
}
.headImg {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
.pc-page-main.edituser {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  .el-form-item {
    margin-bottom: 14px;
  }
}
</style>
