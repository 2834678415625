import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { httpPost } from '@/api';
import { erpConfirm, errorMessage, hasPermission } from '@/utils/publicMethods';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'userName',
      value: '',
      placeholder: '请输入用户名',
    },
    {
      type: 'signSelect',
      prop: 'status',
      value: 'ENABLE',
      options: [
        {
          value: 'ENABLE',
          label: '启用',
        },
        {
          value: 'DISABLE',
          label: '停用',
        },
      ],
      placeholder: '请选择状态',
    },
    {
      type: 'input',
      prop: 'departmentName',
      value: '',
      placeholder: '请输入部门',
    },
    {
      type: 'input',
      prop: 'positionName',
      value: '',
      placeholder: '请输入岗位',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '用户名',
      prop: 'userName',
      minWidth: 120,
    },
    {
      label: '状态',
      prop: 'status',
      component: defineComponent({
        template: `
          <div>
              {{statusToChinese(row.status)}}
          </div>
        `,
        props: ['row'],
        setup() {
          const statusToChinese = (val) => {
            let state = '';
            switch (val) {
              case 'ENABLE': {
                state = '启用';
                break;
              }
              case 'DISABLE': {
                state = '停用';
                break;
              }
            }
            return state;
          };
          return { statusToChinese };
        },
      }),
    },
    {
      label: '部门',
      prop: 'departmentName',
      minWidth: 120,
    },
    {
      label: '岗位',
      prop: 'positionName',
      minWidth: 120,
    },
    {
      label: 'Email',
      prop: 'email',
      minWidth: 120,
    },
    {
      label: '性别',
      prop: 'sex',
      component: defineComponent({
        template: `
          <div >
              {{sexToChinese(row.sex)}}
          </div>
        `,
        props: ['row'],
        setup() {
          const sexToChinese = (val) => {
            let state = '-';
            switch (val) {
              case 1: {
                state = '男';
                break;
              }
              case 2: {
                state = '女';
                break;
              }
            }
            return state;
          };
          return { sexToChinese };
        },
      }),
    },
    {
      label: '手机',
      prop: 'mobileNo',
      minWidth: 120,
    },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
          <el-button type="primary"  v-if="row.id != 1 && hasPermission('uac:user:save')" plain @click="edit(row)">编辑</el-button>
          <el-button type="warning"  v-if="row.status === 'ENABLE' && row.id != 1 && hasPermission('uac:user:modifyUserStatusById')" plain @click="disableFn(row)">停用</el-button>
          <el-button v-if="row.status === 'DISABLE' && row.id != 1 &&hasPermission('uac:user:modifyUserStatusById')" type="primary" plain @click="enableFn(row)">启用</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const edit = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.openDrawer(2);
            // Instance.setupState.isShowEditDialog = true;
            // Instance.setupState.methodType = 'UPDATE';
            // Instance.setupState.dialogName = '编辑用户';
          };
          /** 停用 */
          const disableFn = (row) => {
            const confirmMsg = '确定停用' + row.loginName + '吗?';
            erpConfirm(confirmMsg)
              .then(async () => {
                const res = await httpPost(
                  '/uac/crmUser/modifyUserStatusById',
                  {
                    status: 'DISABLE',
                    userId: row.id,
                  }
                );
                if (res.code === 200) {
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          /** 启用 */
          const enableFn = (row) => {
            const confirmMsg = '确定启用' + row.loginName + '吗?';
            erpConfirm(confirmMsg)
              .then(async () => {
                const res = await httpPost(
                  '/uac/crmUser/modifyUserStatusById',
                  {
                    status: 'ENABLE',
                    userId: row.id,
                  }
                );
                if (res.code === 200) {
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { edit, disableFn, enableFn };
        },
      }),
      prop: '',
      width: 200,
    },
  ];
  return { filterOptions, columnList, tableRef, hasPermission };
};
